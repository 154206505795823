import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store  from './redux/store';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
</BrowserRouter>
  </React.StrictMode>
);
const cors = require('cors');
const corsOptions ={
    origin:'http://localhost:3002', 
    credentials:true,            //access-control-allow-credentials:true
    optionSuccessStatus:200
}
App.use(cors(corsOptions));
reportWebVitals();
